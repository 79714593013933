import React from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'

import { Cart, SEO } from '../components'

const CartPage = ({ path }: any) => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t('cart.title')} path={path} />
      <Cart />
    </>
  )
}

export default CartPage
